module.exports =  {
    "redeemCode": {
        "expired": "The eSIM has expired.",
        "address": "SM - DP + address",
        "scanqrcode": "Option 1: Scan QR code",
        "entertheeSIM": "Option 2: Enter the eSIM activation Information",
        "assistance": "If you have any inquiries, please reach out to technical support team for assistance.",
        "redeemforanesim": "How to redeem for an eSIM",
        "inquiry": "Redeem / Inquiry",
        "processing": "Please wait, processing is in progress.",
        "completed": "Redeem / Inquiry process has been completed.",
        "redeemesim": "Redeem for an eSIM",
        "failed": "Redemption failed, please reach out to technical support team for assistance.",
        "redemptiontime": "Redemption time",
        "error": "Redemption error, please reach out to technical support team for assistance.",
        "hasexpired": "Redemption code has expired, please enter the correct redemption code.",
        "hasbeenredeemed": "Redemption code has been redeemed.",
        "canceled": "Redemption code has been canceled.",
        "reachassistance": "Redemption code error, please reach out to technical support team for assistance.",
        "invalid": "Invalid redemption code, please enter the correct redemption code.",
        "ok": "Ok",
        "viewesim": "View eSIM",
        "information": "View eSIM information",
        "activationcode": "The activation code",
        "install": "Ways to install the eSIM",
        "provide": "The page only provide support for eSIM redemption. If you have any questions about using eSIM, please contact technical support team.",
        "viewredeemedcode": "Confirm to view the redeemed eSIM code",
        "copysuccessful": "Copy successful.",
        "before": "Please activate the eSIM before [xxxx-xx-xx]",
        "reissued": "Note: Once the redemption code is redeemed, the eSIM QR code must be activated within 30 days, otherwise it will expire and cannot be reissued.",
        "correct": "Please enter the correct ICCID.",
        "correctcode": "Please enter the correct Redemption code.",
        "entertheredemptioncode": "Enter the Redemption code",
        "confirmredemption": "Confirm redemption",
        "continueredemption": "Continue redemption",
        "slider":"Please drag the slider to complete the verification",
        "verificationfailed":'Please press and hold the slider and drag it to the far right',
        "verificationpassed":'Verification passed',
        "entertheiccid":'Please enter the redemption code / ICCID',
        "entercorrecttheiccid":'Please enter the correct redemption code / ICCID',
        "activatetime":'Please activate eSIM plan before :',
        "installeSIM":'Install eSIM Now',
        "clickInstallEsim":'Tab this button to install eSIM immediately',
        "enterEmail":'Please enter your email address'
    },
    "contactUs":{
        "contactus":'Contact Us',
        "locales":'English',
        "responetime":'Response time：',
        "wechatcustomer":'WeChat Customer Service',
        "languages":'Languages：Chinese , English',
        "available":'*No telephone support is available.',
        "copy":'Copied',
    },
    "collectingInformation": {
        "collaboration": "Looking Forward to Our Collaboration",
        "wonderfuljourney": "Choose TSim, and Begin a Wonderful Journey.",
        "expandingbusiness": "Unlock Endless Possibilities for Expanding Your Business!",
        "workingdays": "Complete the form in just 2 minutes, and our specialist will contact you within 2 working days!",
        "ourclients": `<h1>Become Our <span>Clients</span></h1>`,
        "ourclients2": `Become Our <span>Clients</span>`,
        "optimalbusiness": "Delivering industry-leading solutions tailored to develop the optimal business strategies and pricing for you.",
        "oursuppliers": `<h1>Become Our <span>Suppliers</span></h1>`,
        "oursuppliers2": `Become Our <span>Suppliers</span>`,
        "businessopportunities": "Expand your product reach to the global users and unlock new business opportunities.",
        "startednow": "Get Started Now!",
        "contactus": `If you are already our customer and need support, please contact us at:<br/> <a href="https://service.tsimtech.com/contact/en">Help Center - Contact Us</a>`,
        "supportteam": "Our support team will contact you as soon as possible!",
        "copyright": "Copyright © 2025 TSim Tech",
        "providesuitable": "Complete the form in just 2 minutes, and our specialist will contact you within 2 working days to provide suitable solutions and quotes!",
        "aboutcompany": "Company ",
        "aboutbusiness": "Tell us about your business",
        "aboutproduct": "Product ",
        "understandneeds": "Let us better understand your needs",
        "contactfun": "Contact",
        "contactyou": "How to contact you",
        "companyname": "Company Name",
        "entercompany": "Please enter your company name",
        "countryregion": "Country/Region",
        "enterarea": "Country/Region",
        "yearofcompany": "Year of company establishment",
        "companywebsite": "Company Website",
        "saleschannels": "Product Sales Channels (multiple choices available)",
        "ownedplatform": "Independent Platform (Self-built/Owned Platform)",
        "otawebsite": "Third-party Platform/OTA Website",
        "mobileapplications": "Mobile Applications",
        "offlineretail": "Offline Retail",
        "otherchannels": "Other: Please state other sales channels",
        "monthlyquantity": "Estimated Monthly Quantity of Items Sold",
        "Belownum": "Below 3,000",
        "newopportunities": "Set sail in new fields; we will help you find new opportunities in all aspects! ",
        "startedquickly": "Whether it is business/product adaptation or technical customer service, we are always on call to help you get started quickly from zero to one.",
        "numtonum": "3,000 - 10,000",
        "marketfoundation": "Stabilise the market foundation, we will help you break through new heights! ",
        "sustainedgrowth": "Our professional team helps you explore new opportunities in the broader market and achieve sustained growth.",
        "orabove": "10,000 or above",
        "forwardrapidly": "Your business is moving forward rapidly, we help you take it one step further! ",
        "specification": "Expand your business scope comprehensively, customise exclusive product lines, and improve the specification layout to make your products stand out in fierce competition.",
        "wishproduct": "Product types you wish to know about (multiple choices available)",
        "esim": "eSIM",
        "physical": "Physical SIM Card",
        "back": "Back",
        "next": "Next",
        "contactname": "Contact Name",
        "enteryourname": "Please enter your name",
        "email": "Email",
        "enteryouremail": "Please enter your email ",
        "getcode": "Get Verification Code",
        "entercode": "Enter the Verification code",
        "phonenumber": "Phone Number",
        "enterphone": "Please enter your phone number",
        "submit": "Submit",
        "edit": "Edit",
        "confirm": "Confirm",
        "confidentialityagreement": `I have read and agreed to the <a href="" style="color: #c86d02;text-decoration: underline">confidentiality agreement</a>.`,
        "wellreceived": "Thank you! Your information is well received!",
        "keepcontact": "Our specialist will contact you within 2 working days. We look forward to working with you. Please keep your contact information open so we can respond faster.",
        "chancesgrowth": "Complete the form in just 2 minutes, and our specialist will contact you within 2 working days to explore more chances of business growth.",
        "negotiatebussines": "Better negotiate with you",
        "negotiatemore": "2. Product ",
        "understandstrengths": "Better understand your strengths",
        "howcontactyou": "How to contact you",
        "nameofcompany": "Name of the company",
        "countryregioncity": "Country/Region/City",
        "havewebsite": "Do you have any website for your company?",
        "yes": "Yes",
        "no": "No",
        "producttypes": "Product Types (multiple choices available)",
        "globalroaming": "Global Roaming",
        "onlydata": "Data",
        "datacalls": "Data + Calls",
        "datasms": "Data + SMS",
        "datacallssms": "Data + Calls + SMS",
        "local": "Local",
        "preferredregions": "Preferred Regions (multiple choices available) ",
        "asia": "Asia",
        "europe": "Europe",
        "africa": "Africa",
        "northamerica": "North America",
        "southamerica": "South America",
        "oceania": "Oceania",
        "settlementurl": "Settlement URL",
        "or": "or",
        "uploadsettlementurl": "Upload your settlement sheet",
        "supportapi": "Support API?",
        "apiurl": "API URL",
        "uploadapi": "Upload your API document",
        "supportcdr": "Support CDR?",
        "confirmsubmit": "Confirm and Submit",
        "followinginformation": `You are about to submit the following information. Please review it carefully before clicking “<b>Submit</b>”.`,
        "correctcompany": "Please enter the correct company name.",
        "correctname": "Please enter the correct contact name.",
        "correctemail": "Please enter the correct email address.",
        "correctverification": "Please enter the correct verification code.",
        "selectarea": "Please select the company’s area",
        "selectyear": "Please select the year of company establishment",
        "uploadxlsx": "Only .xlsx file type is supported for upload.",
        "uploadpdf": "Only .pdf file type is supported for upload.",
        "upload": "Upload",
        "entersettlementurl": "Please enter the settlement URL.",
        "enterapiurl": "Please enter the API URL.",
        "apidocument": "API Document",
        "sec": "sec",
        "selectsalef": "Please select the product sales channel.",
        "selectmonthsales": "Please select the estimated monthly sales quantity.",
        "selecthopeType": "Please select the type of product you wish to know about.",
        "selectareacode": "Enter an international area code and select it.",
        "areacode":"Calling code",
        "pleaseenter": "Please fill in the required field.",
        "confirmagreement": "Please check that you have read and agree to the confidentiality agreement.",
        "selectprovidingtype": "Please select the product types you are providing",
        "selectresources": "Please select the type of product resources",
        "selectprefereregions": "Please select the prefered regions",
        "selectisapi": "Please select that you support API or not",
        "selectiscdr": "Please select that you support CDR or not",
        "selectyear2":"Please select",
        "enterwebsite":"Please enter a correct website",
        "commerceplatform":"E-commerce Platform",
        "travelplatform":"Online Travel Agencies Platform",
        "entercountrytip":"Enter country name/calling code to search",
        "aboutTSim":"How did you learn about TSim? (Multiple selection allowed)",
        "socialMedia":"Social Media / Search Engine",
        "onlinePlatform":"Online Platform / Third-party Platform",
        "referral":"Referral / Exhibition / Event",
        "other":"Other",
        "heardaboutTSim":"Please select how you heard about TSim ",
        "briefdescription":"Please provide a brief description",
        "entersouce":"Please specify",
        "enterplatform":"Please specify platform name",
        "enterreferral":"Please specify referrer or event name",
    },
}