module.exports =  {
    "redeemCode": {
        "expired": "eSIM套餐已過期",
        "address": "SM - DP + 地址",
        "scanqrcode": "方式一：掃描二維碼",
        "entertheeSIM": "方式二：輸入啟用碼",
        "assistance": "如有疑問請聯繫客服諮詢。",
        "redeemforanesim": "如何兌換eSIM",
        "inquiry": "兌換/查詢",
        "processing": "兌換/查詢中...",
        "completed": "兌換/查詢成功",
        "redeemesim": "兌換eSIM",
        "failed": "兌換失敗，請聯繫客服",
        "redemptiontime": "兌換時間",
        "error": "兌換異常，請聯繫客服諮詢。",
        "hasexpired": "兌換碼已失效，請輸入正確的兌換碼",
        "hasbeenredeemed": "兌換碼已兌換",
        "canceled": "兌換碼已取消",
        "reachassistance": "兌換碼異常，請聯繫客服諮詢。",
        "invalid": "兌換碼無效，請輸入正確的兌換碼",
        "ok": "我知道了",
        "viewesim": "查看eSIM",
        "information": "查看eSIM資訊",
        "activationcode": "啟用碼",
        "install": "添加eSIM的方式",
        "provide": "僅支持兌換eSIM，有關eSIM套餐的疑問，請聯繫客服諮詢。",
        "viewredeemedcode": "確定查看已兌換碼的eSIM信息？",
        "copysuccessful": "複製成功",
        "before": "請在xxxx-xx-xx前啟用",
        "reissued": "請注意：兌換碼一旦兌換，eSIM QR碼必須在30天內啟用，過期將失效，恕不補發。",
        "correct": "請輸入正確的 ICCID",
        "correctcode": "請輸入正確的兌換碼",
        "entertheredemptioncode": "輸入兌換碼",
        "confirmredemption": "點選兌換",
        "continueredemption": "繼續兌換",
        "slider":"請拖動滑塊完成驗證",
        "verificationfailed":'請按住滑塊，拖動到最右側',
        "verificationpassed":'驗證通過',
        "entertheiccid":'請輸入兌換碼 / ICCID',
        "entercorrecttheiccid":'請輸入正確的兌換碼 / ICCID',
        "activatetime":'請於此日期前啟用：',
        "installeSIM":'立即安裝 eSIM',
        "clickInstallEsim":'點擊此按鈕可立即安裝 eSIM',
        "enterEmail":'請輸入電郵'
    },
    "contactUs":{
        "contactus":'聯繫我們',
        "locales":'繁體中文',
        "responetime":'服務時間：',
        "wechatcustomer":'聯繫微信客服',
        "languages":'語言：中文、英語',
        "available":'*不支持語音通話服務',
        "copy":'複製成功',
    },
    "collectingInformation": {
        "collaboration": "期待與您的合作",
        "wonderfuljourney": "選擇TSim，相信會是一個美好的開始",
        "expandingbusiness": "開啟無限商機，拓展您的業務！",
        "workingdays": "只需2分鐘填寫表格，我們的負責人將在2個工作天內聯繫您！",
        "ourclients": `<h1>成為我們的<span>商業夥伴</span></h1>`,
        "ourclients2": `成為我們的<span>商業夥伴</span>`,
        "optimalbusiness": "提供業界領先的解決方案，為您量身訂製最佳商業策略與定價。",
        "oursuppliers": "<h1>成為我們的<span>供應商</span></h1>",
        "oursuppliers2": "成為我們的<span>供應商</span>",
        "businessopportunities": "讓您的產品觸達全球用戶，幫助您解鎖新的商業機會。",
        "startednow": "立即開始！",
        "contactus": `若您已是我們的客戶並需要支援，請聯絡我們：<br/><a href="https://service.tsimtech.com/contact/en">幫助中心 - 聯絡我們</a>`,
        "supportteam": "我們的支援團隊會盡快與您聯絡！",
        "copyright": "著作權 © 2025 TSim Tech",
        "providesuitable": "只需2分鐘填寫，我們的負責人會在2個工作天內聯絡您並為您匹配方案和報價",
        "aboutcompany": "關於您的公司",
        "aboutbusiness": "讓我們知道您的業務",
        "aboutproduct": "關於產品",
        "understandneeds": "讓我們了解您的需求",
        "contactfun": "聯絡方式",
        "contactyou": "讓我們知道如何聯絡",
        "companyname": "公司名稱",
        "entercompany": "請輸入您所在的公司名稱",
        "countryregion": "所在國家/地區",
        "enterarea": "所在國家/地區",
        "yearofcompany": "成立時間（年）",
        "companywebsite": "公司網站",
        "saleschannels": "您的產品銷售渠道（可複選）",
        "ownedplatform": "獨立站（自建平台）",
        "otawebsite": "第三方/OTA平台",
        "mobileapplications": "行動應用程式",
        "offlineretail": "實體零售",
        "otherchannels": "其他（請說明）",
        "monthlyquantity": "預計每月銷量",
        "Belownum": "3,000組以下",
        "newopportunities": "啟航新領域，我們全方位協助您尋找新的機會！",
        "startedquickly": "無論是業務/產品適配還是技術客服，我們都隨時待命，助您從零到一，快速起步。",
        "numtonum": "3,000-10,000組",
        "marketfoundation": "穩固市場根基，我們助您突破業務新高度！",
        "sustainedgrowth": "我們的專業團隊為您在更廣闊的市場中挖掘新機遇，實現持續增長。",
        "orabove": "10,000組以上",
        "forwardrapidly": "您的業務飛速前進，我們助您更進一步！",
        "specification": "全面擴展業務範疇，定制專屬產品線，完善規格布局，讓您的產品在激烈競爭中脫穎而出。",
        "wishproduct": "您希望了解的產品類型（可複選）",
        "esim": "eSIM業務",
        "physical": "實體SIM卡業務",
        "back": "上一步",
        "next": "下一步",
        "contactname": "聯絡人姓名",
        "enteryourname": "請輸入您的姓名",
        "email": "電子郵件",
        "enteryouremail": "請輸入您的電子郵件地址",
        "getcode": "取得驗證碼",
        "entercode": "請輸入驗證碼",
        "phonenumber": "電話號碼",
        "enterphone": "請輸入您的電話號碼",
        "submit": "提交",
        "edit": "編輯",
        "confirm": "確認",
        "confidentialityagreement": `我已閱讀並同意<span style="color: #c86d02;text-decoration: underline">保密協定</span>`,
        "wellreceived": "感謝！您的資訊我們已收到！",
        "keepcontact": "我們的負責人將於2個工作天內與您聯繫，請保持聯絡暢通。誠摯期待與您合作！",
        "chancesgrowth": "只需2分鐘填寫，我們的負責人將在2個工作天內與您聯繫，並協助您探索更多商業成長機會。",
        "negotiatebussines": "讓我們了解您的業務",
        "negotiatemore": "進一步了解",
        "understandstrengths": "讓我們知道您的優勢",
        "howcontactyou": "讓我們知道如何與您聯絡",
        "nameofcompany": "公司名稱",
        "countryregioncity": "所在國家/地區/城市",
        "havewebsite": "是否有公司網站",
        "yes": "是",
        "no": "否",
        "producttypes": "您提供的產品類型（可複選）",
        "globalroaming": "國際漫遊資源",
        "onlydata": "僅數據",
        "datacalls": "數據+電話",
        "datasms": "數據+簡訊",
        "datacallssms": "數據+電話+簡訊",
        "local": "本地資源",
        "preferredregions": "優勢地區（可複選）",
        "asia": "亞洲",
        "europe": "歐洲",
        "africa": "非洲",
        "northamerica": "北美",
        "southamerica": "南美",
        "oceania": "太平洋地區",
        "settlementurl": "報價網址",
        "or": "或",
        "uploadsettlementurl": "上傳您的報價單",
        "supportapi": "是否支援API接口?",
        "apiurl": "API地址",
        "uploadapi": "请上传您的API文档",
        "supportcdr": "是否支援CDR?",
        "confirmsubmit": "確認資訊並提交",
        "followinginformation": `您即將提交以下資訊，請確認資訊後點擊「<b>立即提交</b>」`,
        "correctcompany": "請輸入正確的公司名稱",
        "correctname": "請輸入正確的聯絡人姓名",
        "correctemail": "請輸入正確的電子信箱地址",
        "correctverification": "請輸入正確的驗證碼",
        "selectarea": "請選擇公司所在區域",
        "selectyear": "請選擇公司成立時間",
        "uploadxlsx": "僅支援上傳.xlsx類型檔案",
        "uploadpdf": "僅支援上傳.pdf類型檔案",
        "upload": "上傳",
        "entersettlementurl": "請輸入報價網址",
        "enterapiurl": "請輸入API網址",
        "apidocument": "API檔案",
        "sec": "秒",
        "selectsalef": "請選擇產品銷售管道",
        "selectmonthsales": "請選擇預估每月銷量",
        "selecthopeType": "請選擇希望了解的產品類型",
        "selectareacode": "請輸入並選擇國際區號",
        "areacode":"國際區號",
        "pleaseenter": "請輸入",
        "confirmagreement": "請確認已閱讀並同意保密協定。",
        "selectprovidingtype": "請選擇您提供的產品類型",
        "selectresources": "請選擇資源類型",
        "selectprefereregions": "請選擇優勢地區",
        "selectisapi": "請選擇是否支援API連結",
        "selectiscdr": "請選擇是否支援CDR",
        "selectyear2":"請選擇",
        "enterwebsite":"請輸入正確的公司網站",
        "commerceplatform":"在線零售",
        "travelplatform":"在線旅遊代理平台",
        "entercountrytip":"支援英文國名/國際區號搜尋",
        "aboutTSim":"您是如何得知TSim的？（可複選）",
        "socialMedia":"社群平台 / 搜尋引擎",
        "onlinePlatform":"第三方電商平台 / 代理通路",
        "referral":"親友推薦 / 展覽活動",
        "other":"其他",
        "heardaboutTSim":"請選擇您是如何得知TSim的",
        "briefdescription":"請簡單描述",

        "entersouce":"請填寫具體来源",
        "enterplatform":"請填寫平台名稱",
        "enterreferral":"請填寫推薦人或活動名稱",

    },
}