module.exports =  {
    "redeemCode": {
        "expired": "eSIM有効期限切れ",
        "address": "SM - DP + 住所",
        "scanqrcode": "方法1:QRコードのスキャン",
        "entertheeSIM": "方法2:eSIM 情報を手入力する",
        "assistance": "ご不明な点がございましたら、カスタマーサービスまでお問い合わせください。",
        "redeemforanesim": "eSIMの切替方法",
        "inquiry": "引き換え/問い合わせ",
        "processing": "引き換え/問い合わせ中…",
        "completed": "引き換え/問い合わせ完了",
        "redeemesim": "eSIMの引き換え",
        "failed": "引き換え失敗、サポートセンターまでお問い合わせください。",
        "redemptiontime": "引き換え時間",
        "error": "引き換えエラー、サポートセンターまでお問い合わせ下さい。",
        "hasexpired": "引き換えコード失敗、正しいコードを入力してください。",
        "hasbeenredeemed": "入力いただいたコードは既に引き換え済みです。",
        "canceled": "引き換えコードは取り消しされました。",
        "reachassistance": "引き換えコードに異常が確認されたため、サポートセンターにご連絡ください。",
        "invalid": "引き換えコードは無効です。正しいIDをご入力ください。",
        "ok": "承知しました。",
        "viewesim": "esimを見る",
        "information": "eSIM情報の確認",
        "activationcode": "アクティベーションコード",
        "install": "eSIM方法の追加",
        "provide": "eSIMのみ切替可能です、eSIMパックについてご不明な点がございましたら、サポートセンターにお問い合わせください。",
        "viewredeemedcode": "eSIMコードの表示をしてもよろしいでしょうか。",
        "copysuccessful": "コピー成功",
        "before": "xxxx-xx-xxの前にご使用ください。",
        "reissued": "注意:コード交換後、eSIM QRコードは30日以内に有効化する必要があります。有効期限を過ぎるとQRコードは無効となり、再発行することはできませんのでご注意ください。",
        "correct": "正しいICCIDをご入力してください",
        "correctcode": "正しい引き換えコードをご入力してください",
        "entertheredemptioncode": "引き換えコードをご入力してください",
        "confirmredemption": "引き換えを確認する",
        "continueredemption": "引き換えを続ける",
        "slider":"スライダーをドラッグして、検証を完了してください。",
        "verificationfailed":'スライダーを押し続けて、右端までドラッグしてください。',
        "verificationpassed":'検証に成功しました。',
        "entertheiccid":'引き換えコード・ICCIDを入力してください',
        "entercorrecttheiccid":'正確の引き換えコード・ICCIDを入力してください',
        "activatetime":'この日までにアクティベートしてください：',
        "installeSIM":'今すぐeSIMを追加します',
        "clickInstallEsim":'ボタンを押してすぐにeSIMを追加できます',
        "enterEmail":'メールアドレスを入力してください'
    },
    "contactUs":{
        "contactus":'お問い合わせ',
        "locales":'日本語',
        "responetime":'サービス時間：',
        "wechatcustomer":'WeChat カスタマーサービス',
        "languages":'対応言語：中国語・英語',
        "available":'*電話での対応は受け付けておりません。',
        "copy":'コード成功',
    },
    "collectingInformation": {
        "collaboration": "協業を楽しみにしています。",
        "wonderfuljourney": "TSimで、新たなスタートへ。",
        "expandingbusiness": "無限のビジネスチャンスを開拓し、あなたの事業を拡大しましょう！",
        "workingdays": "簡単2分でフォームをご記入いただくだけで、担当スタッフが2営業日以内にご連絡いたします。",
        "ourclients": `<h1><span>ビジネスパートナー</span>に</h1>`,
        "ourclients2": `<span>ビジネスパートナー</span>に`,
        "optimalbusiness": "業界トップのソリューションで、最適なビジネス戦略と価格をサポート。",
        "oursuppliers": `<h1><span>サプライヤー</span>を募集中</h1>`,
        "oursuppliers2": `<span>サプライヤー</span>を募集中`,
        "businessopportunities": "製品を世界中のユーザーに届け、新たなビジネスチャンスを開きましょう。",
        "startednow": "今すぐスタート！",
        "contactus": `既にご契約いただいているお客様で、サポートが必要な場合は、こちらまでご連絡ください。<br/><a href="https://service.tsimtech.com/contact/en">ヘルプセンター - お問い合わせ</a>`,
        "supportteam": "サポートチームがすぐにご連絡いたします！",
        "copyright": "著作権 © 2025 TSim Tech",
        "providesuitable": "2分でフォームにご記入いただくだけで、担当スタッフが2営業日以内にご連絡し、プランと見積もりをお届けします。",
        "aboutcompany": "会社情報",
        "aboutbusiness": "業務内容を教えてください",
        "aboutproduct": "製品について",
        "understandneeds": "ニーズを教えてください",
        "contactfun": "連絡方法",
        "contactyou": "連絡方法を教えてください",
        "companyname": "会社名",
        "entercompany": "所属会社名",
        "countryregion": "国/地域",
        "enterarea": "国/地域",
        "yearofcompany": "設立年",
        "companywebsite": "ウェブサイト",
        "saleschannels": "販売チャネル（複数選択可）",
        "ownedplatform": "自社既存WEB内にて",
        "otawebsite": "サードパーティー/OTAプラットフォーム",
        "mobileapplications": "モバイルアプリ",
        "offlineretail": "実店舗",
        "otherchannels": "その他（ご記入ください）",
        "monthlyquantity": "予想月間販売量",
        "Belownum": "3,000個以下",
        "newopportunities": "新たな領域へ一歩を踏み出しましょう！",
        "startedquickly": "業務や製品から技術カスタマーサービスまで、ゼロから一への素早いスタートをサポートいたします。",
        "numtonum": "3,000-10,000個",
        "marketfoundation": "市場の基盤を強化し、ビジネスの新たな高みへと導きます！",
        "sustainedgrowth": "専門チームとより広い市場で新しいチャンスを見つけ、持続的な成長を実現します。",
        "orabove": "10,000個以上",
        "forwardrapidly": "急速に発展するあなたの事業をさらに推進します！",
        "specification": "事業範囲の全面的な拡大、専用製品ラインのカスタマイズ、規格レイアウトの最適化により、競争の激しい市場で御社の製品を際立たせます。",
        "wishproduct": "興味のある製品種類（複数選択可）",
        "esim": "eSIM",
        "physical": "物理SIMカード",
        "back": "前へ",
        "next": "次へ",
        "contactname": "ご担当者名",
        "enteryourname": "ご担当者名を入力してください",
        "email": "メールアドレス",
        "enteryouremail": "メールアドレスを入力してください",
        "getcode": "認証コードを取得",
        "entercode": "認証コードの入力",
        "phonenumber": "電話番号",
        "enterphone": "電話番号を入力してください",
        "submit": "送信",
        "edit": "編集",
        "confirm": "確認",
        "confidentialityagreement": `私は<a href="" style="color: #c86d02;text-decoration: underline">秘密保持契約</a>を読み、同意しました。`,
        "wellreceived": "ありがとうございます！情報を受け取りました！",
        "keepcontact": "担当スタッフが2営業日内にご連絡いたしますので、ご連絡が取れるようお願いいたします。心よりお待ちしております！",
        "chancesgrowth": "2分でフォームにご記入いただくだけで、担当スタッフが2営業日以内にご連絡し、ビジネス成長の新たな機会をご提案いたします。",
        "negotiatebussines": "事業内容を教えてください",
        "negotiatemore": "2. 製品情報",
        "understandstrengths": "強みを教えてください",
        "howcontactyou": "連絡方法を教えてください",
        "nameofcompany": "会社名",
        "countryregioncity": "所在国/地域/都市",
        "havewebsite": "会社にウェブサイトがありますか？",
        "yes": "はい",
        "no": "いいえ",
        "producttypes": "提供する製品種類（複数選択可）",
        "globalroaming": "国際ローミングリソース",
        "onlydata": "データのみ",
        "datacalls": "データ+通話",
        "datasms": "データ+メッセージ",
        "datacallssms": "データ+通話+メッセージ",
        "local": "ローカルリソース",
        "preferredregions": "優勢な地域（複数選択可）",
        "asia": "アジア",
        "europe": "ヨーロッパ",
        "africa": "アフリカ",
        "northamerica": "北米",
        "southamerica": "南米",
        "oceania": "オセアニア",
        "settlementurl": "見積りURLを入力してください",
        "or": "または",
        "uploadsettlementurl": "見積書をアップロードしてください。",
        "supportapi": "API接続をサポートしていますか？",
        "apiurl": "API URL",
        "uploadapi": "APIドキュメントをアップロードしてください。",
        "supportcdr": "CDRをサポートしていますか？",
        "confirmsubmit": "情報の確認および送信",
        "followinginformation": `以下の情報をご確認いただき、「<b>今すぐ送信</b>」ボタンをクリックして提出してください。`,
        "correctcompany": "正確な会社名を入力してください。",
        "correctname": "正確な担当者名を入力してください",
        "correctemail": "正確なメールアドレスを入力してください。",
        "correctverification": "正確な認証コードを入力してください。",
        "selectarea": "会社の所在地を選択してください",
        "selectyear": "会社の設立年月を選択してください",
        "uploadxlsx": ".xlsx型ファイルのみアップロード可能です。",
        "uploadpdf": ".pdf型ファイルのみアップロード可能です。",
        "upload": "アップロード",
        "entersettlementurl": "見積書URLを入力してください。",
        "enterapiurl": "API URLを入力してください。",
        "apidocument": "APIドキュメント",
        "sec": "秒",
        "selectsalef": "製品の販売チャネルを選択してください。",
        "selectmonthsales": "予想月間販売数を選択してください。",
        "selecthopeType": "業務で使用したい製品の種類を選択してください。",
        "selectareacode": "国際市外局番を入力して選択してください。",
        "areacode":"国際電話",
        "pleaseenter": "必須項目に入力してください。",
        "confirmagreement": "秘密保持契約を読み、同意したことを確認してください。",
        "selectprovidingtype": "提供する製品種類を選択してください。",
        "selectresources": "リソース属性を選択してください。",
        "selectprefereregions": "リソースの優勢地域を選択してください。",
        "selectisapi": "API接続できるかどうか選択してください。",
        "selectiscdr": "CDRを見えるかどうか選択してください。",
        "selectyear2":"設立年月",
        "enterwebsite":"URLを正しく入力してください",
        "commerceplatform":"ネット通販サイト",
        "travelplatform":"オンライン旅行代理店プラットフォーム",
        "entercountrytip":"国名/国際電話番号を入力して検索できます",
        "aboutTSim":"TSimをどのようにお知りになりましたか？（複数選択可）",
        "socialMedia":"SNS / 検索エンジン",
        "onlinePlatform":"ECサイト・代理店",
        "referral":"知人の紹介 / 展示会・イベント",
        "other":"その他",
        "heardaboutTSim":"TSimをどのようにお知りになりましたかをご選択ください。",
        "briefdescription":"簡単にご説明ください。",
        "entersouce":"具体的な出典をご記入ください",
        "enterplatform":"プラットフォーム名をご記入ください",
        "enterreferral":"ご紹介者またはイベント名をご記入ください",
    },
}